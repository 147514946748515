<template>
  <component :is="commonComponent" id="terms" title="Terms of services">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-row">
        Welcome to {{ domainConfig['IAmURL'] }}. By accessing or using our website, you agree to
        comply with and
        be bound by the following terms and conditions. Please review them carefully.
      </div>

      <div class="common-content-row">
        <ol>
          <li>
            <strong>Acceptance of Terms</strong>: By using this site, you agree to these Terms of
            services. If you do not agree, please do not use the site.
          </li>
          <li>
            <strong>Use of Content</strong>: All resources on this site are public domain materials
            and are available for personal, educational, and non-commercial use. You may not sell or
            redistribute this content for commercial purposes without explicit permission.
          </li>
          <li>
            <strong>User Conduct</strong>: You agree not to use the site for any unlawful or harmful
            activities. This includes, but is not limited to, transmitting harmful code, engaging in
            fraudulent activities, or infringing on the rights of others.
          </li>
          <li>
            <strong>Intellectual Property</strong>: While the literary content is public domain, the
            site design, logos, and branding are proprietary. You may not reproduce or use them
            without prior written consent from {{ domainConfig['IAmURL'] }}.
          </li>
          <li>
            <strong>User Submissions</strong>: Any feedback, comments, or suggestions you provide
            are considered non-confidential and may be used by us without restriction.
          </li>
          <li>
            <strong>Modification of Terms</strong>: We reserve the right to modify these terms at
            any time. Changes will be effective immediately upon posting. Your continued use of the
            site constitutes acceptance of the updated terms.
          </li>
          <li>
            <strong>Termination</strong>: We reserve the right to terminate or restrict your access
            to the site for any reason, including violation of these terms.
          </li>
        </ol>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Terms of services | ${this.domainConfig?.['meta']?.title || 'Default Title'}`,
      meta: [
        {
          name: 'description',
          content: `${this.domainConfig?.['meta']?.des || 'Default des'}`
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>